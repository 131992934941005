import React from 'react';
import { Link } from 'react-router-dom';
import './Hero.css';

function Hero() {
  return (
    <section className="hero" id="home">
      <div className="container">
        <h1>Never Miss an Event Opportunity</h1>
        <p>Get daily updates on new event permits and trend insights for your city</p>
        <Link to="/login" className="btn">Start Tracking Now</Link>
      </div>
    </section>
  );
}

export default Hero;