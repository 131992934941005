import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Hero from './Hero';
import Features from './Features';
import Stats from './Stats';
import HowItWorks from './HowItWorks';
import WhatItCosts from './WhatItCosts';
import Footer from './Footer';

function LandingPage() {
  const location = useLocation();
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);

  useEffect(() => {
    if (location.hash === '#features' && featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '#pricing' && pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="landing-page">
      <Hero />
      <div id="features" ref={featuresRef}>
        <Features />
      </div>
      <Stats />
      <HowItWorks />
      <div id="pricing" ref={pricingRef}>
        <WhatItCosts />
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
