import React from 'react';
import { Users, Building2, Clock, Heart } from 'lucide-react';
import './Stats.css';

const Stats = () => {
  return (
    <div style={{ position: 'relative', zIndex: 0 }}>
      <section className="stats-section">
        <div className="stats-container">
          <div className="stats-grid">
            <div className="stat-card">
              <div className="stat-icon">
                <Users size={28} className="icon" />
              </div>
              <div className="stat-content">
                <h3 className="stat-number">50K+</h3>
                <p className="stat-label">Event Permits</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <Building2 size={28} className="icon" />
              </div>
              <div className="stat-content">
                <h3 className="stat-number">2+</h3>
                <p className="stat-label">Cities</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <Clock size={28} className="icon" />
              </div>
              <div className="stat-content">
                <h3 className="stat-number">Daily</h3>
                <p className="stat-label">Updates</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <Heart size={28} className="icon" />
              </div>
              <div className="stat-content">
                <h3 className="stat-number">99%</h3>
                <p className="stat-label">Satisfaction</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Stats;