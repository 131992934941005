import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB_ACs4ZTCHnzLCSKKae7aMeMqdUABWZs4",
  authDomain: "permit-pulse.firebaseapp.com",
  projectId: "permit-pulse",
  storageBucket: "permit-pulse.appspot.com",
  messagingSenderId: "647899490801",
  appId: "1:647899490801:web:b0431b961cddd01db87c50",
  measurementId: "G-V4LY90381H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

// Create root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render React application
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); 