import React from 'react';
import { Database, Inbox, LineChart } from 'lucide-react';
import './Features.css';

function Features() {
  const features = [
    {
      icon: <Database size={24} />,
      title: "Daily Updates to Your Inbox",
      description: "Get new event permits across your selected city each morning"
    },
    {
      icon: <Inbox size={24} />,
      title: "AId Event Permit Filtering",
      description: "AId Technology ensures only the most relevant permits are sent to you"
    },
    {
      icon: <LineChart size={24} />,
      title: "Market Insights",
      description: "Analyze permit trends and identify opportunities in your area (coming soon)"
    }
  ];

  return (
    <div style={{ position: 'relative', zIndex: 1 }}>
      <section className="features" id="features">
        <div className="container">
          <div className="features-grid">
            {features.map((feature, index) => (
              <div key={index} className="feature-card">
                <div className="feature-icon-wrapper">
                  {feature.icon}
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;