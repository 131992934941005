import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions } from 'firebase/functions';
import { useAuth } from '../contexts/AuthContext';
import './CheckoutPage.css';

// Initialize Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Firebase function URL
const CHECKOUT_URL = 'https://us-central1-permit-pulse.cloudfunctions.net/createCheckoutSessionV2';

function CheckoutPage() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    const initializeCheckout = async () => {
      try {
        // Validate authentication
        if (!user) {
          throw new Error('User not authenticated. Please log in to continue.');
        }

        // Validate plan details from location state
        if (!location.state?.planDetails) {
          throw new Error('No plan details provided. Please select a plan first.');
        }

        const { planDetails, selectedCities } = location.state;

        // Validate plan price ID
        const priceId = planDetails.stripePriceId;
        if (!priceId) {
          throw new Error('Invalid plan configuration. Please try selecting a different plan.');
        }

        // Validate selected cities
        if (!selectedCities || selectedCities.length === 0) {
          throw new Error('Please select at least one city to continue.');
        }

        // Create checkout session request
        const response = await fetch(CHECKOUT_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          credentials: 'omit',
          body: JSON.stringify({
            priceId,
            planId: planDetails.id,
            selectedCities,
            customerId: user.uid,
            customerEmail: user.email
          })
        });

        // Handle non-200 responses
        if (!response.ok) {
          const errorData = await response.json().catch(() => ({ 
            error: `HTTP error! status: ${response.status}`
          }));
          throw new Error(errorData.error || 'Failed to create checkout session');
        }

        // Parse response and validate session ID
        const data = await response.json();
        if (!data.sessionId) {
          throw new Error('Invalid response from server: No session ID received');
        }

        // Initialize Stripe
        const stripe = await stripePromise;
        if (!stripe) {
          throw new Error('Failed to initialize payment system');
        }

        // Redirect to Stripe Checkout
        const { error: stripeError } = await stripe.redirectToCheckout({
          sessionId: data.sessionId
        });

        if (stripeError) {
          throw new Error(stripeError.message);
        }
      } catch (err) {
        console.error('Checkout error:', err);
        setError(err.message || 'An unexpected error occurred during checkout');
      } finally {
        setLoading(false);
      }
    };

    initializeCheckout();
  }, [location.state, user]);

  // Handle navigation back to plans
  const handleBackToPlan = () => {
    navigate('/plan-selection', { 
      state: { 
        selectedCities: location.state?.selectedCities 
      }
    });
  };

  if (error) {
    return (
      <div className="checkout-container">
        <div className="checkout-error">
          <div className="error-icon">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-6 w-6" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
              />
            </svg>
          </div>
          <h2 className="error-title">Checkout Error</h2>
          <p className="error-message">{error}</p>
          <button 
            className="back-button"
            onClick={handleBackToPlan}
          >
            Back to Plans
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="checkout-container">
      <div className="checkout-loader">
        <div role="status" className="loading-spinner">
          <svg 
            aria-hidden="true" 
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" 
            viewBox="0 0 100 101" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" 
              fill="currentColor"
            />
            <path 
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" 
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
        <p className="loading-text">
          {loading ? 'Initializing checkout...' : 'Redirecting to payment...'}
        </p>
      </div>
    </div>
  );
}

export default CheckoutPage;