import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './PaymentSuccess.css';

function PaymentSuccess() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (!sessionId) {
      navigate('/dashboard');
      return;
    }

    const checkSession = async () => {
      try {
        setLoading(false);
      } catch (error) {
        console.error('Error verifying payment:', error);
        navigate('/dashboard');
      }
    };

    if (user) {
      checkSession();
    }
  }, [user, navigate, searchParams]);

  if (loading) {
    return <div className="payment-success-container">Verifying payment...</div>;
  }

  return (
    <div className="payment-success-container">
      <h1>Payment Successful!</h1>
      <p>Thank you for your subscription.</p>
      <button onClick={() => navigate('/dashboard')} className="dashboard-button">
        Go to Dashboard
      </button>
    </div>
  );
}

export default PaymentSuccess;