import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('🔵 AuthProvider - Setting up auth listener');
    
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log('🔵 AuthProvider - Auth state changed:', { 
        hasUser: !!currentUser,
        userId: currentUser?.uid 
      });
      
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    loading
  };

  console.log('🔵 AuthProvider - Current context value:', { 
    hasUser: !!user,
    loading 
  });

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
