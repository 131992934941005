import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import './WhatItCosts.css';

function WhatItCosts() {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansRef = collection(db, 'plans');
        const snapshot = await getDocs(plansRef);
        
        if (!snapshot.empty) {
          const plansData = snapshot.docs
            .map(doc => ({
              id: doc.id,
              ...doc.data(),
              price: parseFloat(doc.data().price) || 0,
              MaxCities: parseInt(doc.data().MaxCities) || 1,
              features: doc.data().features || []
            }))
            .sort((a, b) => a.price - b.price);
          
          setPlans(plansData);
        }
      } catch (error) {
        console.error("Error fetching plans:", error);
        setError("Unable to load pricing plans at this time.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  if (loading) {
    return (
      <section className="what-it-costs">
        <div className="container">
          <h2>Simple, Transparent Pricing</h2>
          <div className="loading">Loading pricing plans...</div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="what-it-costs">
        <div className="container">
          <h2>Simple, Transparent Pricing</h2>
          <div className="error-message">
            {error}
            <p>Please check back later or contact support.</p>
          </div>
        </div>
      </section>
    );
  }

  if (!plans.length) {
    return null;
  }

  const getFeatures = (plan) => {
    const maxCities = parseInt(plan.MaxCities);
    const cityText = maxCities === 1 
      ? '1 City' 
      : `${maxCities} Cities`;

    return [
      cityText,
      ...plan.features
    ];
  };

  return (
    <section className="what-it-costs">
      <div className="container">
        <h2>Simple, Transparent Pricing</h2>
        <p className="section-subtitle">Choose the plan that fits your needs</p>
        
        <div className="pricing-grid">
          {plans.map((plan) => (
            <div key={plan.id} className="pricing-card">
              <h3>{plan.name}</h3>
              <div className="price">
                ${plan.price}<span className="period">/month</span>
              </div>
              <div className="features-box">
                {getFeatures(plan).map((feature, index) => (
                  <div key={index} className="feature">{feature}</div>
                ))}
              </div>
              <a href="/login" className="get-started">Get Started</a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhatItCosts;
