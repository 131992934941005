import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc, collection } from 'firebase/firestore';
import ReCAPTCHA from 'react-google-recaptcha';
import './SignUpWithEmail.css';

function SignUpWithEmail() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const navigate = useNavigate();

    const isPasswordValid = (password) => {
        const minLength = password.length >= 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        
        return minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setError(null);
        setSuccess(null);

        if (!name || !email || !password || !confirmPassword) {
            setError('Please fill in all fields');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (!isPasswordValid(password)) {
            setError('Password does not meet requirements');
            return;
        }

        if (!captchaToken) {
            setError('Please complete the CAPTCHA');
            return;
        }

        setIsLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const userId = userCredential.user.uid;
            
            await setDoc(doc(db, 'users', userId), {
                createdAt: new Date(),
                email: email.toLowerCase(),
                lastLogin: new Date(),
                name: name.trim(),
                user: []
            });

            // Wait for auth state to be fully updated
            await new Promise((resolve) => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    if (user) {
                        unsubscribe();
                        resolve();
                    }
                });
            });

            setSuccess('Account created successfully!');
            
            navigate('/plan-selection', { 
                state: { 
                    userEmail: email.toLowerCase(),
                    userName: name.trim()
                },
                replace: true
            });

        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                setError('An account with this email already exists');
            } else {
                setError('Failed to create account. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="signup-container">
            <div className="signup-form">
                <div className="header-section">
                    <h2>Create Your Account</h2>
                    <p className="header-subtitle">Daily permit insights, straight to your inbox</p>
                </div>

                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            type="text"
                            placeholder="Enter your name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            placeholder="Create a password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <div className="password-requirements">
                            Password must be at least 8 characters long and contain uppercase, lowercase, 
                            numbers, and special characters.
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                            type="password"
                            placeholder="Confirm your password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>

                    <div className="captcha-container">
                        <ReCAPTCHA
                            sitekey="6LejCW4qAAAAAOIa5-MztUkdiTNFi5mTAygsdaAt"
                            onChange={(token) => setCaptchaToken(token)}
                        />
                    </div>

                    <button 
                        type="submit" 
                        className="signup-button"
                        disabled={isLoading || !captchaToken}
                    >
                        {isLoading ? 'Creating Account...' : 'Create Account'}
                    </button>
                </form>

                <div className="back-to-login">
                    Already have an account? <Link to="/login">Sign in</Link>
                </div>
            </div>
        </div>
    );
}

export default SignUpWithEmail;