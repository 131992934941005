import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

function Dashboard() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (!currentUser) {
        navigate('/login');
        return;
      }
      setUser(currentUser);
      
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData({
            ...data,
            displayName: data.name || currentUser.displayName || 'User'
          });
          
          if (data.subscription && data.subscription.status === 'active') {
            const { subscription } = data;
            
            if (subscription.planId) {
              try {
                const planDoc = await getDoc(doc(db, 'plans', subscription.planId));
                if (planDoc.exists()) {
                  const plan = planDoc.data();
                  setPlanData({
                    name: plan.name || 'Subscription Plan',
                    price: plan.price || 0,
                    maxCities: subscription.maxCities || 1,
                    features: plan.features || [],
                    status: subscription.status,
                    startDate: subscription.startDate,
                    endDate: subscription.endDate,
                    billingCycle: subscription.billingCycle
                  });
                }
              } catch (error) {
                console.error('Error fetching plan data:', error);
                setPlanData(null);
              }
            }
          } else {
            setPlanData(null);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setPlanData(null);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleChangePlan = () => {
    navigate('/plan-selection');
  };

  const handleChangeCity = () => {
    navigate('/city-selection');
  };

  const handleCancelSubscription = () => {
    navigate('/cancel-subscription');
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (loading) {
    return (
      <div className="dashboard-container">
        <div className="loading">Loading your dashboard...</div>
      </div>
    );
  }

  const isGoogleUser = user?.providerData?.[0]?.providerId === 'google.com';

  return (
    <div className="dashboard-container">
      {/* User Profile Section */}
      <div className="user-card">
        {isGoogleUser && user?.photoURL ? (
          <div className="user-profile-section">
            <img src={user.photoURL} alt="Profile" className="user-profile-photo" />
            <div className="user-info">
              <h1 className="user-name">{userData?.displayName}</h1>
              <p className="user-email">{user?.email}</p>
            </div>
          </div>
        ) : (
          <div className="user-info">
            <h1 className="user-name">{userData?.displayName}</h1>
            <p className="user-email">{user?.email}</p>
          </div>
        )}
      </div>

      <div className="dashboard-grid">
        {/* Subscription Section */}
        <div className="section-card">
          <div className="section-header">
            <h2 className="section-title">Subscription Details</h2>
          </div>
          <div className="subscription-details">
            <h3>{planData?.name || 'No active plan'}</h3>
            <p className="subscription-price">
              Price: ${planData?.price || 0}/{planData?.billingCycle || 'month'}
            </p>
            <p className="subscription-limit">
              Allowed Cities: {planData?.maxCities || 0}
            </p>
            {planData?.features && planData.features.length > 0 && (
              <div className="features-list">
                <h4 className="features-title">Features:</h4>
                {planData.features.map((feature, index) => (
                  <p key={index}>✓ {feature}</p>
                ))}
              </div>
            )}
          </div>
          <div className="button-group">
            <button 
              className="action-button primary-button"
              onClick={handleChangePlan}
            >
              {planData ? 'Change Plan' : 'Select a Plan'}
            </button>
            {planData && (
              <button 
                className="action-button secondary-button"
                onClick={handleCancelSubscription}
              >
                Cancel Plan
              </button>
            )}
          </div>
        </div>

        {/* Cities Section */}
        <div className="section-card">
          <div className="section-header">
            <h2 className="section-title">Your Cities</h2>
            <button 
              className="action-button primary-button"
              onClick={handleChangeCity}
              disabled={!planData || (userData?.selectedCities?.length >= planData.maxCities && planData.maxCities > 1)}
            >
              Change City
            </button>
          </div>
          
          <div className="cities-list">
            {userData?.selectedCities?.length > 0 ? (
              userData.selectedCities.map((city, index) => (
                <div key={index} className="city-item">
                  {city}
                </div>
              ))
            ) : (
              <div className="no-cities">
                {planData 
                  ? 'No cities selected yet.' 
                  : 'Subscribe to a plan to add cities.'}
              </div>
            )}
          </div>
        </div>
      </div>

      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
}

export default Dashboard;