import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyB_ACs4ZTCHnzLCSKKae7aMeMqdUABWZs4",
  authDomain: "permit-pulse.firebaseapp.com",
  projectId: "permit-pulse",
  storageBucket: "permit-pulse.appspot.com",
  messagingSenderId: "647899490801",
  appId: "1:647899490801:web:b0431b961cddd01db87c50",
  measurementId: "G-V4LY90381H"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); 