import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function PrivateRoute({ children }) {
    const { user, loading } = useAuth();
    
    console.log('🔵 PrivateRoute - Auth State:', { 
        loading, 
        hasUser: !!user,
        userId: user?.uid 
    });

    if (loading) {
        console.log('⏳ PrivateRoute - Loading auth state');
        return (
            <div className="loading-container">
                <div className="loading-spinner">Loading...</div>
            </div>
        );
    }

    if (!user) {
        console.log('❌ PrivateRoute - No user, redirecting to login');
        return <Navigate to="/login" replace />;
    }

    console.log('✅ PrivateRoute - User authenticated, rendering children');
    return children;
}

export default PrivateRoute;
