import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import SignUpWithEmail from './components/SignUpWithEmail';
import Dashboard from './components/Dashboard';
import PlanSelection from './components/PlanSelection';
import PrivateRoute from './components/PrivateRoute';
import CitySelection from './components/CitySelection';
import CancelSubscription from './components/CancelSubscription';
import CheckoutPage from './components/CheckoutPage';
import PaymentSuccess from './components/PaymentSuccess';
import './styles/main.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup-email" element={<SignUpWithEmail />} />
            <Route 
              path="/plan-selection" 
              element={
                <PrivateRoute>
                  <PlanSelection />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/city-selection" 
              element={
                <PrivateRoute>
                  <CitySelection />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/checkout" 
              element={
                <PrivateRoute>
                  <CheckoutPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/cancel-subscription" 
              element={
                <PrivateRoute>
                  <CancelSubscription />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/payment-success" 
              element={
                <PrivateRoute>
                  <PaymentSuccess />
                </PrivateRoute>
              } 
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;