import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { CheckCircle2 } from 'lucide-react';
import './PlanSelection.css';

function PlanSelection() {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [billingCycle, setBillingCycle] = useState('month');
  const navigate = useNavigate();

  // Define price IDs mapping using environment variables
  const STRIPE_PRICE_IDS = {
    'national': {
      'month': process.env.REACT_APP_STRIPE_NATIONAL_MONTH_PRICE_ID,
      'year': process.env.REACT_APP_STRIPE_NATIONAL_YEAR_PRICE_ID
    },
    'local': {
      'month': process.env.REACT_APP_STRIPE_LOCAL_MONTH_PRICE_ID,
      'year': process.env.REACT_APP_STRIPE_LOCAL_YEAR_PRICE_ID
    }
  };

  const getStripePriceId = (planType, cycle) => {
    const type = planType.toLowerCase();
    if (!STRIPE_PRICE_IDS[type] || !STRIPE_PRICE_IDS[type][cycle]) {
      console.error(`No price ID found for plan: ${type}, cycle: ${cycle}`);
      return null;
    }
    return STRIPE_PRICE_IDS[type][cycle];
  };

  // Calculate price based on billing cycle
  const calculatePrice = (basePrice, cycle) => {
    if (cycle === 'year') {
      return basePrice * 10; // Annual price is 10x monthly (2 months free)
    }
    return basePrice;
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansRef = collection(db, 'plans');
        const snapshot = await getDocs(plansRef);
        
        if (!snapshot.empty) {
          const plansData = snapshot.docs
            .map(doc => {
              const data = doc.data();
              const basePrice = parseFloat(data.price) || 0;
              return {
                id: doc.id,
                ...data,
                price: calculatePrice(basePrice, billingCycle),
                MaxCities: parseInt(data.MaxCities) || 1,
                features: data.features || []
              };
            })
            .sort((a, b) => a.price - b.price);
          
          setPlans(plansData);
        }
      } catch (error) {
        console.error("Error fetching plans:", error);
        setError("Unable to load pricing plans at this time.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [billingCycle]); // Re-fetch when billing cycle changes

  const handlePlanSelect = async (planId) => {
    setLoading(true);
    setError(null);

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('Please log in to select a plan');
      }

      const selectedPlan = plans.find(p => p.id === planId);
      if (!selectedPlan) {
        throw new Error('Selected plan not found');
      }

      // Get plan type from the plan name
      const planType = selectedPlan.name.toLowerCase().includes('national') ? 'national' : 'local';
      const stripePriceId = getStripePriceId(planType, billingCycle);

      if (!stripePriceId) {
        throw new Error('Stripe price ID not found for selected plan and billing cycle');
      }

      const navigationData = {
        planId: selectedPlan.id,
        planMaxCities: selectedPlan.MaxCities || 1,
        name: selectedPlan.name,
        features: selectedPlan.features,
        billingCycle,
        price: selectedPlan.price,
        stripePriceId
      };

      navigate('/city-selection', { state: navigationData });
    } catch (error) {
      console.error('Plan selection error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="plan-selection-container">
      <div className="plan-selection-header">
        <h1>Choose Your Plan</h1>
        <p>Start your journey with Event Permit Pro today</p>
        
        <div className="billing-toggle">
          <span className={billingCycle === 'month' ? 'active' : ''}>Monthly</span>
          <label className="switch">
            <input
              type="checkbox"
              checked={billingCycle === 'year'}
              onChange={() => setBillingCycle(prev => prev === 'month' ? 'year' : 'month')}
            />
            <span className="slider round"></span>
          </label>
          <span className={billingCycle === 'year' ? 'active' : ''}>
            Yearly (2 months free)
          </span>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="plans-container">
        {plans.map((plan) => (
          <div 
            key={plan.id}
            className={`plan-card ${plan.popular ? 'popular' : ''}`}
          >
            {plan.popular && (
              <div className="popular-badge">Most Popular</div>
            )}
            
            <h2>{plan.name}</h2>
            
            <div className="price">
              <span className="currency">$</span>
              <span className="amount">{calculatePrice(plan.price)}</span>
              <span className="interval">/{billingCycle}</span>
            </div>
            
            <ul className="features">
              <li>
                <CheckCircle2 className="feature-icon" size={20} />
                <span>{plan.maxCities} {plan.maxCities === 1 ? 'City' : 'Cities'}</span>
              </li>
              {plan.features.map((feature, index) => (
                <li key={index}>
                  <CheckCircle2 className="feature-icon" size={20} />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
            
            <button
              className="select-plan-button"
              onClick={() => !loading && handlePlanSelect(plan.id)}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Select Plan'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlanSelection;