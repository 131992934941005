import React from 'react';
import { Linkedin, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Event Permit Pro</h3>
          <div className="contact-info">
            <a href="mailto:info@eventpermitpro.com" className="contact-link">
              <Mail size={20} />
              <span>info@eventpermitpro.com</span>
            </a>
            <a 
              href="https://www.linkedin.com/company/event-permit-pro" 
              target="_blank" 
              rel="noopener noreferrer"
              className="contact-link"
            >
              <Linkedin size={20} />
              <span>Connect on LinkedIn</span>
            </a>
          </div>
        </div>

        <div className="footer-section">
          <h4>Quick Links</h4>
          <nav className="footer-links">
            <Link to="/#features">Features</Link>
            <Link to="/#pricing">Pricing</Link>
            <Link to="/login">Get Started</Link>
          </nav>
        </div>

        <div className="footer-section legal">
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms">Terms of Service</a>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; {currentYear} Event Permit Pro. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
