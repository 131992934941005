import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const checkSubscriptionAndNavigate = async (userId) => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    
    if (!userDoc.exists()) {
      navigate('/plan-selection');
    } else {
      navigate('/dashboard');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        lastLogin: new Date(),
      }, { merge: true });

      await checkSubscriptionAndNavigate(user.uid);
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const isNewUser = !userDoc.exists();
      
      if (isNewUser) {
        const userData = {
          createdAt: new Date(),
          email: user.email,
          lastLogin: new Date(),
          name: user.displayName,
          user: []
        };
        
        if (user.photoURL) {
          userData.photoURL = user.photoURL;
        }
        
        await setDoc(doc(db, 'users', user.uid), userData);
        navigate('/plan-selection');
      } else {
        await setDoc(doc(db, 'users', user.uid), {
          lastLogin: new Date()
        }, { merge: true });
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Failed to sign in with Google');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <div className="header-section">
          <h2>Get Started with Event Permit Pro</h2>
          <p className="header-subtitle">Daily permit insights, straight to your inbox</p>
        </div>
        {error && <div className="error-message">{error}</div>}
        
        <div className="button-container">
          <button onClick={handleGoogleSignIn} className="auth-button">
            Sign up with Google
          </button>
          
          <Link to="/signup-email" className="auth-button">
            Sign up with Email
          </Link>
        </div>

        <div className="or-divider">OR</div>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Password
              <Link to="/forgot-password" className="forgot-password">
                Forgot Password?
              </Link>
            </label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">
            Sign In
          </button>
        </form>

        <div className="back-to-login">
          Don't have an account? <Link to="/signup-email">Sign up</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;