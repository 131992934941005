import React from 'react';
import { UserPlus, MapPin, Inbox } from 'lucide-react';
import './HowItWorks.css';

function HowItWorks() {
  const steps = [
    {
      icon: <UserPlus size={24} />,
      title: "1. Create Account",
      description: "Quick setup with your business email"
    },
    {
      icon: <MapPin size={24} />,
      title: "2. Select Your City",
      description: "Choose your area of interest for permit tracking"
    },
    {
      icon: <Inbox size={24} />,
      title: "3. Get Daily Updates",
      description: "Receive your first permit update tomorrow morning"
    }
  ];

  return (
    <section className="how-it-works" id="how-it-works">
      <div className="container">
        <h2>Start Tracking in Minutes</h2>
        <p className="section-subtitle">Three simple steps to stay informed</p>
        
        <div className="steps-container">
          {steps.map((step, index) => (
            <div key={index} className="step-card">
              <div className="step-icon">
                {step.icon}
              </div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>

        <div className="action-container">
          <p className="guarantee">Start receiving updates as soon as tomorrow</p>
          <a href="/login" className="cta-button">Get Started Now</a>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;