import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import './CancelSubscription.css';

function CancelSubscription() {
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handleCancel = async () => {
    setIsProcessing(true);
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        navigate('/login');
        return;
      }

      const now = new Date();
      let cancelDate = new Date();
      
      // Get user document to check billing cycle
      const userRef = doc(db, 'users', currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      
      if (!userSnapshot.exists()) {
        throw new Error('User document not found');
      }

      const userData = userSnapshot.data();
      const billingCycle = userData?.subscription?.billingCycle || 'month';
      
      if (billingCycle === 'year') {
        // Set to December 31st of current year
        cancelDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59);
      } else {
        // Set to last day of current month
        cancelDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
      }

      // Update user document with cancel date
      await updateDoc(userRef, {
        'subscription.cancelDate': cancelDate,
        'subscription.status': 'pending_cancellation'
      });

      // Navigate back to dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error canceling subscription:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleKeepSubscription = () => {
    navigate('/dashboard');
  };

  return (
    <div className="cancel-subscription-container">
      <div className="cancel-subscription-card">
        <h1 className="cancel-title">Cancel Subscription</h1>
        
        <div className="cancel-content">
          <div className="warning-icon">⚠️</div>
          <p className="cancel-message">
            Are you sure you want to cancel your subscription?
          </p>
          <p className="cancel-details">
            Your subscription will remain active until the end of your current billing period.
            After that, you will lose access to:
          </p>
          <ul className="cancel-features">
            <li>Real-time permit notifications</li>
            <li>City permit tracking</li>
            <li>Weekly permit reports</li>
          </ul>
        </div>

        <div className="cancel-actions">
          <button 
            className="keep-subscription-button"
            onClick={handleKeepSubscription}
            disabled={isProcessing}
          >
            No, Keep My Subscription
          </button>
          <button 
            className="confirm-cancel-button"
            onClick={handleCancel}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Yes, Cancel Subscription'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CancelSubscription;