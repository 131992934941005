import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import './CitySelection.css';

function CitySelection() {
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [maxCitiesAllowed, setMaxCitiesAllowed] = useState(1);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { planId, planMaxCities, name, features, billingCycle, price, stripePriceId } = location.state || {};

  useEffect(() => {
    const setupUser = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          navigate('/login');
          return;
        }
        setUser(currentUser);

        let userMaxCities = planMaxCities || 1;
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          userMaxCities = userData.subscription?.maxCities || planMaxCities || 1;
          if (userData.selectedCities) {
            setSelectedCities(userData.selectedCities);
          }
        }
        
        setMaxCitiesAllowed(userMaxCities);
      } catch (err) {
        console.error('Error setting up user:', err);
        setError('Failed to load user information. Please try again.');
      }
    };

    const fetchCities = async () => {
      try {
        setLoading(true);
        const citiesRef = collection(db, 'cities');
        const querySnapshot = await getDocs(citiesRef);
        const cityList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCities(cityList);
      } catch (error) {
        console.error("Error fetching cities:", error);
        setError('Failed to load cities. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    setupUser();
    fetchCities();
  }, [navigate, planMaxCities]);

  const handleCityToggle = (cityName) => {
    if (maxCitiesAllowed === 1) {
      setSelectedCities([cityName]);
    } else {
      setSelectedCities(prev => {
        if (prev.includes(cityName)) {
          return prev.filter(c => c !== cityName);
        } else if (prev.length < maxCitiesAllowed) {
          return [...prev, cityName];
        }
        return prev;
      });
    }
  };

  const handleContinue = async () => {
    if (selectedCities.length === 0) {
      setError('Please select at least one city');
      return;
    }

    try {
      setLoading(true);
      
      await updateDoc(doc(db, 'users', user.uid), {
        selectedCities: selectedCities
      });

      navigate('/checkout', {
        state: {
          planDetails: {
            id: planId,
            name,
            price,
            maxCities: planMaxCities,
            features,
            billingCycle,
            stripePriceId
          },
          selectedCities
        }
      });
    } catch (err) {
      console.error('Error saving city selection:', err);
      setError('Failed to save your selection. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading && cities.length === 0) {
    return (
      <div className="city-selection-container">
        <div className="city-selection-header">
          <h1>Loading Cities...</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="city-selection-container">
      <div className="city-selection-header">
        <h1>{maxCitiesAllowed === 1 ? 'Select Your City' : 'Select Your Cities'}</h1>
        <p>
          Choose up to {maxCitiesAllowed} {maxCitiesAllowed === 1 ? 'city' : 'cities'} to monitor event permit activity
        </p>
        {selectedCities.length > 0 && (
          <div className="selection-info">
            {selectedCities.length} of {maxCitiesAllowed} {maxCitiesAllowed === 1 ? 'city' : 'cities'} selected
          </div>
        )}
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="cities-grid">
        {cities.map(city => (
          <div 
            key={city.id} 
            className={`city-card ${selectedCities.includes(city.name) ? 'selected' : ''}`}
            onClick={() => handleCityToggle(city.name)}
          >
            <div className="city-header">
              <h2>{city.name}</h2>
              <div className="state-info">
                <MapPin className="state-icon" size={18} />
                <span>{city.state}</span>
              </div>
            </div>
            
            <button 
              className={`select-button ${selectedCities.includes(city.name) ? 'selected' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                handleCityToggle(city.name);
              }}
            >
              {selectedCities.includes(city.name) ? 'Selected' : 'Select City'}
            </button>
          </div>
        ))}
      </div>

      {cities.length > 0 && selectedCities.length > 0 && (
        <div className="continue-button-container">
          <button 
            onClick={handleContinue}
            disabled={loading || selectedCities.length === 0}
            className="continue-button"
          >
            {loading ? 'Processing...' : 'Continue to Checkout'}
          </button>
        </div>
      )}
    </div>
  );
}

export default CitySelection;